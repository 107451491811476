var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CreatePicklPopup", {
        attrs: {
          OnBoarded: _vm.onBoarded,
          LoadingBrand: _vm.LoadingBrand,
          brandId: _vm.brandId,
          popupId: "create-pickl-popup",
          surveyFeatureAvailable: _vm.surveyFeatureAvailable,
          selfieFeatureAvailable: _vm.selfieFeatureAvailable,
          multiTaskFeature: _vm.multiTaskFeature,
          reimbursementFeature: _vm.reimbursementFeature,
          couponFeature: _vm.couponFeature,
          localPicklFeature: _vm.localPicklFeature
        },
        on: { UpdatePicklList: _vm.picklCreated }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }